import React from "react";
import { socialMediaLinks } from "../../portfolio";
import "./SocialMedia.scss";

export default function SocialMedia() {
    if (!socialMediaLinks.display) {
        return null;
    }

    const renderSocialMediaIcon = (platform, iconClass) => {
        return (
            socialMediaLinks[platform] && (
                <a
                    href={socialMediaLinks[platform]}
                    className={`icon-button ${platform}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${platform} account`}
                >
                    <i className={iconClass} />
                </a>
            )
        );
    };

    return (
        <section className="social-media-div">
            {renderSocialMediaIcon("google", "fas fa-envelope")}
            {renderSocialMediaIcon("linkedin", "fab fa-linkedin-in")}
            {renderSocialMediaIcon("github", "fab fa-github")}
            {renderSocialMediaIcon("gitlab", "fab fa-gitlab")}
            {renderSocialMediaIcon("medium", "fab fa-medium")}
            {renderSocialMediaIcon("stackoverflow", "fab fa-stack-overflow")}
            {renderSocialMediaIcon("kaggle", "fab fa-kaggle")}
            {renderSocialMediaIcon("facebook", "fab fa-facebook-f")}
            {renderSocialMediaIcon("instagram", "fab fa-instagram")}
            {renderSocialMediaIcon("twitter", "fab fa-twitter")}
        </section>
    );
}
