import React, { useContext } from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import { workExperiences } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import "./WorkExperience.scss";

const WorkExperience = () => {
    const { isDark } = useContext(StyleContext);

    if (workExperiences.display) {
        return (
            <section id="experience">
                <Fade bottom duration={1000} distance="20px">
                    <main className="experience-container" id="workExperience">
                        <section>
                            <h1 className="experience-heading">Experiences</h1>
                            <section className="experience-cards-wrapper">
                                <article className="experience-cards-div">
                                    {workExperiences.experience.map((card) => (
                                        <ExperienceCard
                                            key={card.company}
                                            isDark={isDark}
                                            cardInfo={{
                                                company: card.company,
                                                desc: card.desc,
                                                date: card.date,
                                                companylogo: card.companylogo,
                                                role: card.role,
                                                descBullets: card.descBullets,
                                            }}
                                        />
                                    ))}
                                </article>
                            </section>
                        </section>
                    </main>
                </Fade>
            </section>
        );
    }
    return null;
};

export default WorkExperience;
