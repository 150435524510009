import React, {useEffect, useState} from "react";
import {useLocalStorage} from "./utils/hooks/useLocalStorage";
import {Outlet} from "react-router";
import {StyleProvider} from "./contexts/StyleContext";
import {splashScreen} from "./portfolio";
import SplashScreen from "./containers/splashScreen/SplashScreen";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTopButton from "./containers/topbutton/Top";

export default function Root() {
    const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
    const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
    const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(true);

    // Effect hook for splash screen
    useEffect(() => {
        if (splashScreen.enabled) {
            const splashTimer = setTimeout(() => setIsShowingSplashAnimation(false), splashScreen.duration);
            return () => clearTimeout(splashTimer);
        }
    }, []);

    // Function to toggle dark mode
    const toggleDarkMode = () => {
        setIsDark(!isDark);
    };

    return (
        <div className={isDark ? "dark-mode" : null}>
            <StyleProvider value={{ isDark, toggleDarkMode }}>
                {isShowingSplashAnimation && splashScreen.enabled ? (
                    <SplashScreen />
                ) : (
                    <>
                        <Header />
                        <main className={"resume-wrapper"}>
                            <Outlet />
                        </main>
                        <Footer />
                        <ScrollToTopButton />
                    </>
                )}
            </StyleProvider>
        </div>
    );
}
