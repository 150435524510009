import React from "react";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import StackProgress from "../../containers/skillProgress/skillProgress";
import WorkExperience from "../../containers/workExperience/WorkExperience";
import StartupProject from "../../containers/StartupProjects/StartupProject";
import Achievement from "../../containers/achievement/Achievement";
import Blogs from "../../containers/blogs/Blogs";
import Education from "../../containers/education/Education";
import Profile from "../../containers/profile/Profile";
import "./style.scss";

// Main component
export default function Home() {
    return (
        <>
            <Greeting />
            <Skills />
            <StackProgress />
            <Education />
            <WorkExperience />
            <StartupProject />
            <Achievement />
            <Blogs />
            <Profile />
        </>
    );
};
