import React from "react";
import { illustration, techStack } from "portfolio";
import { Fade } from "react-reveal";
import Build from "assets/lottie/build";
import DisplayLottie from "components/displayLottie/DisplayLottie";
import "./Progress.scss";

const StackProgress = () => {
    if (!techStack.viewSkillBars) {
        return null;
    }

    return (
        <Fade bottom duration={1000} distance="20px">
            <main className="skills-container">
                <section className="skills-bar">
                    <h1 className="skills-heading">Proficiency</h1>
                    {techStack.experience.map((exp, i) => {
                        const key = exp.Stack.replace(/\s+/g, "-").toLowerCase(); // Dynamic key generation
                        const progressStyle = {
                            width: exp.progressPercentage,
                        };
                        return (
                            <div key={key} className="skill">
                                <p>{exp.Stack}</p>
                                <div className="meter">
                                    <span style={progressStyle} />
                                </div>
                            </div>
                        );
                    })}
                </section>

                <section className="skills-image">
                    {illustration.animated ? (
                        <DisplayLottie animationData={Build} />
                    ) : (
                        <img alt="Skills" src={require("../../assets/images/skill.svg")} loading={"lazy"} />
                    )}
                </section>
            </main>
        </Fade>
    );
};

export default StackProgress;
