import React from "react";
import { skillsSection } from "../../portfolio";
import "./SoftwareSkill.scss";

const SoftwareSkill = () => {
    return (
        <section className="software-skills-main-div">
            <ul className="dev-icons">
                {skillsSection.softwareSkills.map((skill, i) => {
                    const key = skill.skillName.replace(/\s+/g, "-").toLowerCase();
                    return (
                        <li key={key} className="software-skill-inline" name={skill.skillName}>
                            <i className={skill.fontAwesomeClassname}></i>
                            <p>{skill.skillName}</p>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default SoftwareSkill;
