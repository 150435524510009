import React from "react";
import "./loading.scss";

const Loading = () => {
    return (
        <div className="sk-circle">
            {[...Array(12)].map((_, index) => (
                <div key={"sk-circle" + index} className={`sk-circle${index + 1} sk-child`} />
            ))}
        </div>
    );
};

export default Loading;
