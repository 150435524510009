import React, { useContext } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import StyleContext from "../../contexts/StyleContext";
import {testArticles} from "../../portfolio";

import "./style.scss";

/**
 * Calculate the estimated reading time for the article based on the word count and words per minute rate (default 200)
 * @param article - the article to calculate the reading time for (an array of blocks) - see https://developers.notion.com/reference/block
 * @param wordsPerMinute - the words per minute rate to use for the calculation (default 200) - see https://www.nngroup.com/articles/how-users-read-on-the-web/
 * @returns {number} - the estimated reading time for the article in minutes (rounded up) - see https://www.nngroup.com/articles/how-long-do-users-stay-on-web-pages/
 */
const calculateReadingTime = (article, wordsPerMinute = 200) => {
    const allParagraphs = article.flatMap((block) => block.content);
    const combinedContent = allParagraphs.join(' ');
    const wordCount = combinedContent.split(/\s+/).length;

    return Math.ceil(wordCount / wordsPerMinute);
};

const SocialMediaButtons = ({ isDark, blogUrl }) => {
    const socialMediaButtons = [
        { name: 'Facebook', icon: 'fab fa-facebook', shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogUrl)}` },
        { name: 'Twitter', icon: 'fab fa-twitter', shareUrl: `https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}` },
        { name: 'LinkedIn', icon: 'fab fa-linkedin', shareUrl: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(blogUrl)}` },
    ];

    return (
        <div className="social-media-buttons-container">
            {socialMediaButtons.map((button, index) => (
                <SocialMediaButton isDark={isDark} key={button.name} name={button.name} icon={button.icon} shareUrl={button.shareUrl} />
            ))}
        </div>
    );
};

SocialMediaButtons.propTypes = {
    blogUrl: PropTypes.string.isRequired,
}

const SocialMediaButton = ({ isDark, icon, shareUrl }) => {
    const handleClick = () => {
        window.open(shareUrl, '_blank', '');
    };

    return (
        <button className={`${isDark ? "dark-button" : "light-button"} social-media-button`} onClick={handleClick}>
            <i className={icon} />
        </button>
    );
};

export default function BlogContent() {
    const { blogID } = useParams();
    const { isDark } = useContext(StyleContext);

    const article = testArticles[blogID];
    const readingTime = calculateReadingTime(article.article);
    const articleURL = window.location.href;
    const articleTitle = article.title ?? 'Blog Title';
    const articleDate = article.date ?? new Date().toISOString().split('T')[0];
    const articleCategory = article.category ?? 'Category';

    return <div className={`${isDark ? "bark-wrapper" : ""} blog-wrapper`}>
        <header className={isDark ? "dark-header" : ""}>
            <h1 className={isDark ? "light-title" : ""}>{articleTitle}</h1>

            <div className={"blog-meta-data"}>
                <span className="blog-category">{articleCategory}</span>
                <span className={"blog-date"}>{articleDate}</span>
                <span className="time-to-read">{readingTime + (readingTime !== 1 ? ' mins read' : ' min read')}</span>
            </div>
        </header>

        <section className={`${isDark ? 'dark-wrapper' : ''} blog-content-wrapper`}>
            <article className="blog-content">
                {article.article.map((block) => {
                    return (
                       <React.Fragment key={block.title}>
                           <h3 className={isDark ? "dark-title" : ""}>{block.title}</h3>

                            {block.content.map((paragraph) => <p key={paragraph}>{paragraph}</p>)}
                       </React.Fragment>
                    )
                })}
            </article>

            <aside className={`${isDark ? "dark-sidebar" : "light-sidebar"} blog-sidebar`}>
                <div className={"blog-sidebar-content"}>
                    <h3>Share this article</h3>
                    <SocialMediaButtons isDark={isDark} blogUrl={window.location.href} />
                </div>
            </aside>
        </section>
    </div>;
}
