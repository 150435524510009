import React, { useState, useEffect, lazy, Suspense } from "react";
import { openSource } from "portfolio";
import Contact from "../contact/Contact";
import Loading from "../loading/Loading";

const renderLoader = () => <Loading />;
const GithubProfileCard = lazy(() =>
    import("components/githubProfileCard/GithubProfileCard")
);

const Profile = () => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (openSource.showGithubProfile === "true") {
            const getProfileData = () => {
                fetch("/profile.json")
                    .then((result) => {
                        if (result.ok) {
                            return result.json();
                        }
                    })
                    .then((response) => {
                        setProfile(response.data.user);
                    })
                    .catch((error) => {
                        setProfile(openSource.defaultOptions);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            };
            getProfileData();
        }
    }, []);

    if (openSource.display && openSource.showGithubProfile === "true") {
        if (loading) {
            return <Loading />;
        }

        return (
            <Suspense fallback={renderLoader()}>
                <GithubProfileCard profile={profile} key={profile.id} />
            </Suspense>
        );
    } else {
        return <Contact />;
    }
};

export default Profile;
