import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

export default function Button({ text, className, href, newTab }) {
    return (
        <div className={className}>
            <a
                className="main-button"
                href={href}
                target={newTab ? "_blank" : undefined}
                rel={newTab ? "noopener noreferrer" : undefined}
            >
                {text}
            </a>
        </div>
    );
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
}
