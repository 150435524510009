import {RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";
import Root from "./Root";
import Home from "./pages/Home";
import BlogContent from "./pages/BlogContent";
import './App.scss';

export default function App() {
    const router = createBrowserRouter([{
        element: <Root />,
        children: [
            { path: "/", element: <Home /> },
            { path: "/blogs/:blogID", element: <BlogContent /> },
            { path: "*", element: <div>Not Found</div> },
        ],
    }]);

    return <RouterProvider router={router} />;
}
