import React, { useContext } from "react";
import StyleContext from "../../contexts/StyleContext";
import Loading from "../loading/Loading";
import { greeting } from "../../portfolio";
import "./SplashScreen.css";

const SplashScreen = () => {
    const { isDark } = useContext(StyleContext);

    return (
        <main className={`${isDark ? "dark-mode" : ""} splash-container`}>
            <section className="splash-animation-container">
                <Loading />
            </section>
            <section className="splash-title-container">
                <span className="grey-color"> &lt;</span>
                <span className="splash-title">{greeting.username}</span>
                <span className="grey-color">/&gt;</span>
            </section>
        </main>
    );
};

export default SplashScreen;
