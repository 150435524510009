import React, { createRef, useContext } from "react";
import { Fade, Slide } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import PropTypes from "prop-types";
import "./EducationCard.scss";

const EducationCard = ({ school }) => {
    const imgRef = createRef();
    const { isDark } = useContext(StyleContext);

    const GetDescBullets = ({ descBullets }) => {
        return descBullets
            ? descBullets.map((item) => (
                <li key={item.toString()} className="subTitle">
                    {item}
                </li>
            ))
            : null;
    };

    GetDescBullets.propTypes = {
        descBullets: PropTypes.arrayOf(PropTypes.string)
    };

    if (!school.logo) {
        console.error(`Image of ${school.schoolName} is missing in the education section`);
    }

    const logoSection = school.logo && (
        <section className="education-card-left">
            <img
                crossOrigin={"anonymous"}
                ref={imgRef}
                className="education-roundedimg"
                src={school.logo}
                alt={school.schoolName}
                loading={"lazy"}
          />
      </section>
    );

    const educationTextDetails = (
        <section className="education-text-details">
            <h5 className={`${isDark ? "dark-mode" : ""} education-text-subHeader`}>
              {school.subHeader}
            </h5>

            <p className={`${isDark ? "dark-mode" : ""} education-text-duration`}>
              {school.duration}
            </p>

            <p className="education-text-desc">{school.desc}</p>

            <article className="education-text-bullets">
                <ul>
                    <GetDescBullets descBullets={school.descBullets} />
                </ul>
            </article>
        </section>
    );

    return (
        <>
            <Fade left duration={1000}>
                <section className="education-card">
                    {logoSection}
                    <main className="education-card-right">
                        <h5 className="education-text-school">{school.schoolName}</h5>
                        {educationTextDetails}
                    </main>
                </section>
            </Fade>

            <Slide left duration={2000}>
                <div className="education-card-border" />
            </Slide>
        </>
    );
};

EducationCard.propTypes = {
  school: PropTypes.shape({
    schoolName: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    descBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  })
};

export default EducationCard;
