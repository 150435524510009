import React, {useContext} from "react";
import AchievementCard from "../../components/achievementCard/AchievementCard";
import {achievementSection} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import "./Achievement.scss";
export default function Achievement() {
    const {isDark} = useContext(StyleContext);

    if (!achievementSection.display) {
        return null;
    }

    return (
        <Fade bottom duration={1000} distance="20px">
            <div className="main" id="achievements">
                <div className="achievement-main-div">
                    <div className="achievement-header">
                        <h1
                            className={
                                isDark
                                    ? "dark-mode heading achievement-heading"
                                    : "heading achievement-heading"
                            }
                        >
                            {achievementSection.title}
                        </h1>
                        <p
                            className={
                                isDark
                                    ? "dark-mode subTitle achievement-subtitle"
                                    : "subTitle achievement-subtitle"
                            }
                        >
                            {achievementSection.subtitle}
                        </p>
                    </div>
                    <div className="achievement-cards-div">
                        {achievementSection.achievementsCards.map((card, i) => {
                            return (
                                <AchievementCard
                                    key={card.title}
                                    isDark={isDark}
                                    cardInfo={{
                                        title: card.title,
                                        description: card.subtitle,
                                        image: card.image,
                                        imageAlt: card.imageAlt,
                                        footer: card.footerLink
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </Fade>
    );
}
