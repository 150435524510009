import React, { useContext } from "react";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { illustration, skillsSection } from "../../portfolio";
import { Fade } from "react-reveal";
import codingPerson from "../../assets/lottie/codingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";
import "./Skills.scss";

const Skills = () => {
    const { isDark } = useContext(StyleContext);

    if (!skillsSection.display) {
        return null;
    }

    return (
        <section className={isDark ? "dark-mode main" : "main"} id="skills">
            <main className="skills-main-div">
                <Fade left duration={1000}>
                    <section className="skills-image-div">
                        {illustration.animated ? (
                            <DisplayLottie animationData={codingPerson} />
                        ) : (
                            <img
                                alt="Man Working"
                                src={require("../../assets/images/developerActivity.svg")}
                            />
                        )}
                    </section>
                </Fade>
                <Fade right duration={1000}>
                    <div className="skills-text-div">
                        <h1 className={`${isDark ? "dark-mode" : ""} skills-heading`}>
                            {skillsSection.title}{" "}
                        </h1>
                        <p
                            className={`${isDark ? "dark-mode" : ""} subTitle skills-text-subtitle`}
                        >
                            {skillsSection.subTitle}
                        </p>

                        <SoftwareSkill />

                        <section>
                            {skillsSection.skills.map((skills, i) => {
                                const key = skills[1].replace(/\s+/g, "-").toLowerCase();
                                return (
                                    <p
                                        key={key}
                                        className={`${isDark ? "dark-mode" : ""} subTitle skills-text`}
                                    >
                                        {skills}
                                    </p>
                                );
                            })}
                        </section>
                    </div>
                </Fade>
            </main>
        </section>
    );
};

export default Skills;
