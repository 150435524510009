import React, { useState, useContext } from "react";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.scss";

const ToggleSwitch = () => {
    const styleContext = useContext(StyleContext);
    const [isChecked, setIsChecked] = useState(styleContext.isDark);

    return (
        <label className="switch">
            <input
                type="checkbox"
                checked={styleContext.isDark}
                onChange={() => {
                    styleContext.toggleDarkMode();
                    setIsChecked(!isChecked);
                }}
            />
            <span className="slider round">
        <span className="emoji">{isChecked ? emoji("🌜") : emoji("☀️")}</span>
      </span>
        </label>
    );
};

export default ToggleSwitch;
