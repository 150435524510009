import React, { useContext } from "react";
import Headroom from "react-headroom";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import { greeting, workExperiences, skillsSection, blogSection, talkSection, achievementSection } from "../../portfolio";
import "./Header.scss";

const menuItems = [
    { key: "skills", label: "Skills", display: skillsSection.display },
    { key: "experience", label: "Work Experiences", display: workExperiences.display },
    { key: "achievements", label: "Achievements", display: achievementSection.display },
    { key: "blogs", label: "Blogs", display: blogSection.display },
    { key: "talks", label: "Talks", display: talkSection.display },
];

function Header() {
    const { isDark } = useContext(StyleContext);

    return (
        <Headroom>
            <header className={isDark ? "dark-menu header" : "header"}>
                <a href="/" className="logo">
                    <span className="grey-color"> &lt;</span>
                    <span className="logo-name">{greeting.username}</span>
                    <span className="grey-color">/&gt;</span>
                </a>

                <input className="menu-btn" type="checkbox" id="menu-btn" />

                <label className="menu-icon" htmlFor="menu-btn" style={{ color: "white" }}>
                    <span className={isDark ? "navicon navicon-dark" : "navicon"} />
                </label>

                <ul className={isDark ? "dark-menu menu" : "menu"}>
                    {menuItems.map((item) => item.display && (
                        <li key={item.key}>
                            <a href={`#${item.key}`}>{item.label}</a>
                        </li>
                    ))}

                    <li>
                        <a href={"#contact"}>Contact Me</a>
                    </li>

                    <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <ToggleSwitch />
                        </a>
                    </li>
                </ul>
            </header>
        </Headroom>
    );
}

export default Header;
