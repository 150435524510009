import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import { bigProjects } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import "./StartupProjects.scss";

const ProjectCard = ({ project, isDark }) => {
    const openProjectUrlInNewTab = (url) => {
        if (!url) {
            return;
        }

        const win = window.open(url, "_blank");
        win.focus();
    };

    return (
        <article className={`project-card ${isDark ? "dark-mode project-card-dark" : "project-card-light"}`}>
            <header className="project-image">
                <img
                    src={project.image}
                    alt={project.projectName}
                    className={`${project.scrollPreview ? "project-image-scroll" : ""} card-image`}
                />
            </header>

            <main className="project-detail">
                <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
                    {project.projectName}
                </h5>
                <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}
                >
                    {project.company}
                </p>
                <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}
                >
                    {project.projectDesc}
                </p>
            </main>

            <footer className="project-card-footer">
                {project.footerLink ?
                    project.footerLink.map((link) => (
                        <span
                            key={link.name}
                            className={`${isDark ? 'dark-mode' : ''} ${link?.disabled ? 'disable-link' : ''} project-tag`}
                            onClick={() => openProjectUrlInNewTab(link.url)}
                            rel="noopener noreferrer"
                            onKeyDown={(e) => e.key === "P" && openProjectUrlInNewTab(link.url)}
                        >
                          {link.name}
                        </span>
                    ))
                : null}
            </footer>
        </article>
    );
};

ProjectCard.propTypes = {
    project: PropTypes.object.isRequired,
    isDark: PropTypes.bool.isRequired,
}

export default function StartupProjects() {
    const { isDark } = useContext(StyleContext);

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        // Add event listener to prevent context menu on the entire component
        document.getElementById("projects")?.addEventListener("contextmenu", handleContextMenu);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.getElementById("projects")?.removeEventListener("contextmenu", handleContextMenu);
        };
    }, []);

    if (!bigProjects.display) {
        return null;
    }

    return (
        <Fade bottom duration={1000} distance="20px">
            <section className="main" id="projects">
                <h1 className="skills-heading">{bigProjects.title}</h1>
                <p className={`${isDark ? 'dark-mode' : 'subTitle'} project-subtitle`}>
                    {bigProjects.subtitle}
                </p>

                <main className="projects-container">
                    {bigProjects.projects.map((project) => (
                        <ProjectCard
                            key={project.projectName}
                            project={project}
                            isDark={isDark}
                        />
                    ))}
                </main>
            </section>
        </Fade>
    );
}
