import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import Loading from "containers/loading/Loading";

const DisplayLottie = ({ animationData }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };

    return (
        <Suspense fallback={<Loading />}>
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
        </Suspense>
    );
};

DisplayLottie.propTypes = {
    animationData: PropTypes.object.isRequired,
}

export default DisplayLottie;
