import React from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import { educationInfo } from "../../portfolio";

const Education = () => {
    if (!educationInfo.display) {
        return null;
    }

    return (
        <section className="education-section" id="education">
            <h1 className="education-heading">Education</h1>

            <div className="education-card-container">
                {educationInfo.schools.map((school, index) => {
                    const key = school.schoolName.replace(/\s+/g, "-").toLowerCase();
                    return <EducationCard key={key} school={school} />;
                })}
            </div>
        </section>
    );
};

export default Education;
