import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import "./Greeting.scss";

export default function Greeting() {
    const { isDark } = useContext(StyleContext);

    if (!greeting.displayGreeting) {
        return null;
    }

    return (
        <Fade bottom duration={1000} distance="40px">
            <section className="greet-main" id="greeting">
                <div className="greeting-main">
                    <section className="greeting-text-div">
                        <main>
                            <h1 className={`${isDark ? "dark-mode" : ""} greeting-text`}>
                                {greeting.title}{" "}
                                <span className="wave-emoji">{emoji("👋")}</span>
                            </h1>

                            <p className={`greeting-text-p subTitle ${isDark ? "dark-mode dark-greeting-text" : ""}`}>
                                {greeting.subTitle}
                            </p>

                            <SocialMedia />

                            <section className="button-greeting-div">
                                <Button text="Contact me" href={"#contact"} />
                                {greeting.resumeLink && (
                                    <Button
                                        text="See my resume"
                                        newTab={true}
                                        href={greeting.resumeLink}
                                    />
                                )}
                            </section>
                        </main>
                    </section>

                    <section className="greeting-image-div">
                        {illustration.animated ? (
                            <DisplayLottie animationData={landingPerson} />
                        ) : (
                            <img
                                alt="Man sitting on a table"
                                src={require("../../assets/images/manOnTable.svg")}
                                loading={"lazy"}
                            />
                        )}
                    </section>
                </div>
            </section>
        </Fade>
    );
}
